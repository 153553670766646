.layoutContainer {
    /* Attenzione al fill available mettere sempre prima */
    /* min-height: -webkit-fill-available; */
    height: 100vh;
    background-color: var(--light);
    color: var(--dark);
    position: relative;
    overflow: hidden;
}

.outletContainer {
    height: 100%;
    z-index: 100;
    position: relative;
}

.logo {
    margin-top: 20px;
    height: 50px;
    text-align: center;
}
.logo img {
    width: 300px;
}
.content {
    height: calc(100% - 70px);
}

.pointRight {
    position: absolute;
    top: 230px;
    right: 0;
    transform: translate(0, -50%);
    opacity: 0.05;
    width: 400px;
}
.pointLeft {
    position: absolute;
    bottom: -230px;
    left: 0;
    transform: translate(0, -50%);
    opacity: 0.05;
    width: 400px;
}
