.selectFilter {
  border: 1px solid #f0f0f0;
  margin-right: 10px;
  padding: 7px 0;
  padding-left: 15px;
  padding-right: 30px;
}

.info {
    color: var(--main);
}
.info:hover {
    color: var(--main-light);
    cursor: pointer;
}

.extraFilter {
  margin-block-start: 30px;
}


.export {
  background: rgba(135, 39, 108, 0.03);
  border-radius: 31px;
  display: inline-flex;
  align-items: center;
  column-gap: 10px;
  color: var(--main);
  font-weight: 600;
  font-size: 14px;
  padding: 11px;
  cursor: pointer;
  transition: all 0.3s;
  margin-top: 30px;
  margin-left: 20px;
  min-width: 130px;
  min-height: 44px;
}

.exportMessage {
  color: var(--main);
  font-weight: 600;
  font-size: 14px;
  margin-top: 15px;
  margin-left: 20px;
}

.export svg path {
  stroke: var(--main);
  transition: all 0.3s;
}

.export:hover {
  background: var(--main);
  color: white;
}
.export:hover svg path {
  stroke: white;
}
