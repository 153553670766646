.logout {
    border-radius: 10px;
    padding: 0 5px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 90;
}
.logout img {
    width: 100%;
    max-width: 80px;
    margin-bottom: 30px;
}
.inner {
    background: rgba(217, 217, 217, 0.12);
    padding: 30px;
    width: 460px;
}
.content {
    width: 100%;
}
.header {
    text-align: center;
}
.header > div {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.2em;
}
.header > h1 {
    font-weight: 500;
    font-size: 20px;
    text-transform: capitalize;
    margin-block: 11px 50px;
    color: var(--text-subtitle);
}
.buttonLogin {
    margin-top: 50px;
}
