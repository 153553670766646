.information {
  background-color: white;
  border-radius: var(--border-radius-standard);
  font-size: 15px;
  font-weight: 500;
  position: relative;
}
.information > div {
  padding: 15px 0;
  border-bottom: 1px solid #f0f0f0;
}
.information > div:last-child {
  border-bottom: none;
}

.infoGroup {
  padding: 30px 20px 20px;
}
