.checkboxForm {
  display: inline-block;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.checkboxForm i {
  position: relative;
  display: inline-block;
  margin-top: 5px;
  width: 36px;
  height: 16px;
  background-color: #c9c9c9;
  border-radius: 23px;
  vertical-align: text-bottom;
  transition: all 0.3s linear;
}
.checkboxForm i::before {
  content: '';
  position: absolute;
  left: 0;
  width: 32px;
  height: 12px;
  background-color: #c9c9c9;
  border-radius: 11px;
  transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
  transition: all 0.25s linear;
}
.checkboxForm i::after {
  content: '';
  position: absolute;
  left: 0;
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 11px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
  transform: translate3d(2px, 2px, 0);
  transition: all 0.2s ease-in-out;
}
.checkboxForm:active i::after {
  width: 18px;
  transform: translate3d(2px, 2px, 0);
}
.checkboxForm:active input:checked + i::after {
  transform: translate3d(16px, 2px, 0);
}
.checkboxForm input {
  display: none;
}
.checkboxForm input:checked + i {
  background-color: var(--success-secondary);
}
.checkboxForm input:checked + i::before {
  transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
}
.checkboxForm input:checked + i::after {
  transform: translate3d(22px, 2px, 0);
}
