.label {
    color: var(--text-subtitle-bright);
    font-size: 17px;
    margin-bottom: 10px;
    font-weight: 700;
}
.registered {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-bottom: 20px;
}

.total {
    flex-basis: 40%;
}

.queried {
    display: flex;
    column-gap: 10px;
    flex-basis: 60%;
}

.queried > div {
    flex-basis: 50%;
}

.amount {
    font-weight: 600;
    font-size: 16px;
}
.stat {
    margin-bottom: 10px;
}

.type {
    margin-top: 12px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-weight: 500;
}

.type img {
    max-width: 20px;
}
.percentage {
    background-color: var(--light);
    padding: 5px 10px;
    border-radius: var(--border-radius-medium);
    font-weight: 600;
    font-size: 14px;
    color: var(--success-secondary);
}
.chart {
    text-align: right;
}
.chart img {
    width: 130px;
    height: 58px;
}

/* totalRegistered */
.totalRegistered {
    padding: 24px 16px;
    background: #f9f2fe;
    border: 1px solid rgba(157, 49, 235, 0.16);
    border-radius: var(--border-radius-standard);
}

.totalRegistered > h6,
.dailyRegistered > h6,
.weeklyRegistered > h6 {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 17px;
    color: var(--text-subtitle);
}
.totalRegistered .amount {
    color: #9d31eb;
}

/* Daily */
.dailyRegistered {
    padding: 24px 16px;
    background: #fcf7f1;
    border: 1px solid rgba(164, 109, 45, 0.17);
    border-radius: var(--border-radius-standard);
}
.dailyRegistered .amount {
    color: #fdb259;
}

/* Weekly */
.weeklyRegistered {
    padding: 24px 16px;
    background: #f3fbf8;
    border: 1px solid rgba(37, 152, 109, 0.16);
    border-radius: var(--border-radius-standard);
}
.weeklyRegistered .amount {
    color: #38f0ab;
}

@media screen and (max-width: 767px) {
    .registered {
        flex-direction: column;
        row-gap: 20px;
        grid-template-columns: repeat(2, 1fr);
    }
    .total {
        flex-basis: 100%;
    }
    .queried {
        flex-basis: 100%;
    }
}
@media screen and (max-width: 576px) {
    .queried {
        flex-direction: column;
        row-gap: 20px;
    }
    .registered {
        grid-template-columns: repeat(1, 1fr);
    }
}
