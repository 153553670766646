.popup {
    padding: 30px 20px 20px;
    background-color: white;
    border-radius: var(--border-radius-standard);
    font-size: 15px;
    font-weight: 500;
    position: relative;
}

.close {
    position: absolute;
    right: 20px;
    top: 10px;
    cursor: pointer;
    font-size: 25px;
}
.close:hover {
    color: var(--main);
}

.popup > div {
    padding: 15px 0;
    border-bottom: 1px solid #f0f0f0;
}
.popup > div:last-child {
    border-bottom: none;
}
.editIcon{
    cursor: pointer;
    margin-left: 10px;
}
.editIcon:hover {
    color: var(--main);
}
.updateEmailForm{
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    align-items: center;
}
.errorCall,
.successCall {
	color: var(--error);
	font-size: 12px;
	margin-top: 10px;
	font-weight: 500;
}

.successCall {
	color: var(--success);
    margin-left: 10px;
}
