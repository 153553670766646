.blockUser,
.unblockUser {
  color: var(--error);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  font-weight: 500;
  font-size: 16px;
  transition: all 0.4s;
}
.blockUser:hover,
.unblockUser:hover {
  color: var(--main);
}

.unblockUser {
  color: var(--success-secondary);
}

.info {
  color: var(--main);
}
.info:hover {
  color: var(--main-light);
  cursor: pointer;
}

.popup {
  padding: 20px;
  text-align: center;
}
.popupText {
  margin-block: 25px;
}
.popupButtonGroup {
    display: flex;
    justify-content: center;
    column-gap: 20px;
}