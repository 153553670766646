.pending,
.completed,
.cancelled {
	color: var(--error);
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 8px;
	font-weight: 500;
	font-size: 16px;
	transition: all 0.4s;
}
.completed {
	color: var(--success-secondary);
}
.cancelled {
	color: rgb(161, 0, 0);
}

.disabledCheckbox {
	opacity: 0.3;
	cursor: not-allowed;
}
.disabledCheckbox:hover {
	cursor: not-allowed;
}

.actions {
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 16px;
}

.approvedButton {
	cursor: pointer;
	background-color: var(--success-secondary);
	color: var(--light);
	display: flex;
	align-items: center;
	column-gap: 5px;
	padding: 5px 10px;
	border-radius: 5px;
	font-size: 15px;
}
.rejectButton {
	cursor: pointer;
	color: var(--error);
	font-size: 30px;
}
.approvedButton:hover,
.rejectButton:hover {
	background-color: var(--main);
}

.filterBar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	column-gap: 5px;
	padding-inline: 1rem;
}

.filter {
	margin-top: 30px;
	display: flex;
	align-items: center;
	column-gap: 40px;
	color: #5d6e82;
	font-weight: 500;
	font-size: 16px;
}
.filter > div {
	border-bottom: 1px solid transparent;
	cursor: pointer;
}

.allStatus,
.pendingStatus,
.approvedStatus {
	border-bottom: 1px solid var(--main) !important;
	color: var(--main);
	cursor: pointer;
	white-space: nowrap;
}

.buttons {
	display: flex;
	column-gap: 10px;
	align-items: center;
}

.buttonApproveAll,
.buttonRejectAll {
	padding: 8px;
	white-space: nowrap;
	min-width: 112px;
}
.buttonApproveAll {
	background-color: var(--success-secondary);
	color: white;
	font-weight: 500;
	font-size: 16px;
	cursor: pointer;
	border: 1px solid var(--success-secondary);
}
.buttonRejectAll {
	background-color: var(--error);
	color: white;
	font-weight: 500;
	font-size: 16px;
	cursor: pointer;
	border: 1px solid var(--error);
}
.buttonApproveAll:hover,
.buttonRejectAll:hover {
	background-color: var(--main);
	border: 1px solid var(--main);
}

@media screen and (max-width: 768px) {
	.filter {
		column-gap: 20px;
	}
	.filter > div {
		font-size: 14px;
	}
	.filterBar {
		flex-direction: column;
		row-gap: 10px;
		align-items: initial;
	}
	.buttons {
		margin-block: 10px 5px;
		row-gap: 10px;
		justify-content: flex-end;
	}
	.buttonApproveAll,
	.buttonRejectAll {
		width: 100%;
	}
}

.stats {
	margin-top: 20px;
}
