.page {
    background-color: var(--dark);
    height: 100vh;
    width: 100%;
}
.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}
.center img {
    width: 100%;
    max-width: 750px;
}
.logo {
    margin-top: 20px;
    margin-left: 20px;
    max-height: 30px;
}
.text {
    color: white;
    font-size: 40px;
    font-weight: 700px;
    margin-top: 20px;
}
.text h4 {
    font-weight: 500;
    font-size: 22px;
    margin-top: 15px;
}

@media screen and (max-width: 767px) {
    .text h1 {
        font-size: 30px;
    }
    .text h4 {
        font-size: 18px;
    }
    .pointRight,
    .pointLeft {
        width: 130px;
    }
}
