.login {
    border-radius: 10px;
    padding: 0 5px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.login img {
    width: 100%;
    max-width: 300px;
    margin-bottom: 30px;
}
.inner {
    background: rgba(217, 217, 217, 0.12);
    padding: 30px;
    width: 460px;
}
.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 1;
    font-size: 20px;
}
.closeButton:hover {
    color: var(--main);
}
.heading {
    text-align: center;
}

.heading > img {
    margin-block: 30px;
}

.heading > p {
    max-width: 340px;
    margin-inline: auto;
    margin-block: 12px 31px;
}
.form {
    margin-bottom: 34px;
}
.form div:first-child {
    display: flex;
    column-gap: 10px;
    justify-content: center;
}

.form input {
    width: 60px;
    height: 60px;
    outline: none;
    border-radius: 10px;
    text-align: center;
    border: 1px solid var(--secondary-text-color);
    font-size: 25px;
}

.cta {
    margin-top: 26px;
    font-weight: 400;
    font-size: 14px;
    color: var(--input-placeholder);
    text-align: center;
}
.cta .buttonResend {
    padding: 0;
    background-color: transparent;
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
    text-decoration: none;
    color: var(--main);
    cursor: pointer;
    border: none;
}
.cta .buttonResend:hover {
    background: transparent;
}
.errorCall {
    color: var(--error);
    font-size: 14px;
    margin-top: 5px;
    font-weight: 500;
    text-align: center;
}
.successCall {
    color: var(--success);
    font-size: 14px;
    margin-top: 5px;
    font-weight: 500;
    text-align: center;
}
.return {
    border: none;
}
.return:hover {
    background-color: transparent;
    color: var(--main-light);
}

@media screen and (max-width: 455px) {
    .form input {
        width: 47px;
        height: 47px;
    }
}
@media screen and (max-width: 380px) {
    .form input {
        width: 39px;
        height: 39px;
    }
}
