.adminPanel {
	background-color: #f0f0f0;
	margin-top: 30px;
	border-radius: var(--border-radius-min);
	padding: 30px 20px 20px;
}
.line {
	margin-bottom: 10px;
}

.boxFiled {
	flex-basis: calc(100% - 80px);
}
.inputUsername input {
	padding: 15px;
}
.inputPassword input {
	padding: 15px 38px 15px 15px;
}
.label {
	font-size: 16px;
	font-weight: 600;
	flex-basis: 80px;
	margin-bottom: 15px;
}

.box {
	display: flex;
	align-items: center;
	column-gap: 10px;
	font-weight: 600;
	color: var(--input-placeholder);
	padding: 10px;
}

.error {
	color: red;
	min-height: 20px;
	padding: 1px 5px 0;
	width: 100%;
	margin-top: 1px;
	line-height: 15px;
	font-size: 13px;
	text-align: center;
	color: red;
}

.popup {
	padding: 20px;
	text-align: center;
}
.popupText {
	margin-block: 25px;
}

.textarea {
	width: 100%;
	padding: 15px;
	padding-right: 60px;
	caret-color: var(--dark);
	color: var(--dark);
	border-radius: var(--border-radius-standard);
	transition: all 0.5s;
	font-size: 16px;
	resize: none;
	font-family: 'Montserrat', sans-serif;
	outline: none;
	border: none;
}

.error {
	color: red;
	min-height: 20px;
	padding: 1px 5px 0;
	width: 100%;
	margin-top: 1px;
	line-height: 15px;
	font-size: 13px;
	text-align: center;
	color: red;
}

.buttons {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 20px;
	max-width: 320px;
	margin-left: auto;
	margin-top: 40px;
}

.deleteButton {
	background-color: var(--main);
	color: white;
}

.deleteButton:hover {
	background-color: transparent;
	color: var(--main);
}
.photo {
	display: flex;
	align-items: flex-end;
	gap: 10px;
}

.uploadPhoto {
	background-color: var(--light);
	color: #8b8a8a;
	cursor: pointer;
	display: block;
	height: 50px;
	line-height: 50px;
	max-width: 320px;
	border-radius: var(--border-radius-standard);
	text-align: center;
	flex-grow: 2;
	overflow: hidden;
	line-clamp: 1;
	white-space: nowrap;
	text-overflow: ellipsis;
	padding: 0 20px;
}
.delete {
	background-color: var(--main);
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	border-radius: var(--border-radius-standard);
	cursor: pointer;
	flex-shrink: 0;
}
.delete:hover {
	background-color: var(--main-light);
}

.previewButton {
	display: inline-flex;
	align-items: center;
	max-width: 320px;
	padding: 0 !important;
	border: none !important;
	margin-bottom: 40px;
	gap: 8px;
}
.previewButton:hover {
	background-color: transparent;
	color: var(--main-light);
}
.previewButton svg {
	font-size: 25px;
}
.aspectRatio {
	display: flex;
	gap: 10px;
	margin-bottom: 10px;
}
.aspectRatio > div {
	background-color: #92267391;
	color: var(--light);
	padding: 5px 10px;
	border-radius: var(--border-radius-min);
}
.aspectRatio > div.active {
	background-color: var(--main);
	color: var(--light);
	padding: 5px 10px;
	border-radius: var(--border-radius-min);
}

.aspectRatio > div:hover:not(.active) {
	background-color: var(--main-light);
	cursor: pointer;
}

/* preview */
.body {
	text-align: center;
	overflow-x: hidden;
}

.disclaimer {
	padding: 30px 20px 20px 20px;
	color: var(--dark);
}

.header {
	text-align: center;
	margin-bottom: 20px;
}
.header h3 {
	margin-bottom: 10px;
}
.disclaimer h5 {
	margin-left: 20px;
	margin-block: 30px 20px;
}
.disclaimer p {
	margin-bottom: 20px;
}
.disclaimer .body {
	padding-inline: 10px;
	max-height: 320px;
	overflow-y: auto;
}
.button {
	margin-bottom: 10px;
	margin-top: 30px;
	width: 100%;
	max-width: 200px;
	margin-inline: auto;
}

.site {
	margin-block: 20px;
}
.tag {
	margin-block: 20px;
}

.backgroundImage {
	border-radius: var(--border-radius-min);
	margin-bottom: 10px;
	aspect-ratio: 16 / 9;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}
