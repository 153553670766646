.select {
	background-color: #b0b0b01f;
	outline: none;
	border: 1px solid var(--text-subtitle-bright);
	min-width: 90px;
	position: relative;
	border-radius: 5px;
	margin-block: 8px 24px;
}
.select img {
	width: 24px;
	height: 24px;
	margin-right: 10px;
}
.selected {
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.selected > span {
	display: flex;
	align-items: center;
}
.selected .arrow svg {
	margin-right: 0;
	transition: all 0.5s ease;
}
.selected .arrowOpened svg {
	transform: rotate(180deg);
	margin-right: 0;
	transition: all 0.5s ease;
}
.options {
	border: 1px solid #b0b0b01f;
	border-top: none;
	background-color: #b0b0b01f;
	position: absolute;
	width: 100%;
	left: 0;
	top: 45px;
	max-height: 0px;
	transition: all 0.3s;
	overflow: hidden;
	visibility: hidden;
	z-index: 2;
	opacity: 0;
}
.optionsOpened {
	border: 1px solid #b0b0b01f;
	background-color: #e9e9e9;
	position: absolute;
	border-top: none;
	width: 100%;
	left: 0;
	top: 45px;
	transition: all 0.2s;
	overflow: hidden;
	visibility: visible;
	z-index: 2;
	opacity: 1;
	border-radius: 0 0 5px 5px;
	overflow: overlay;
	overflow-y: scroll;
}

.options > div,
.optionsOpened > div,
.select {
	padding: 12px 22px;
	display: flex;
	align-items: center;
	cursor: pointer;
}
.optionsOpened > div:hover {
	background-color: var(--main);
	color: white;
}
.options > div svg,
.optionsOpened > div svg,
.select svg {
	font-size: 20px;
	margin-right: 10px;
	color: var(--text-subtitle-bright);
}
.optionsOpened > div:hover svg {
	color: white;
}

.chainBox {
	display: flex;
	align-items: center;
	justify-content: space-between;
	column-gap: 10px;
	margin-block: 8px 24px;
}
.selectChain {
	border: 1px solid var(--text-subtitle-bright);
	padding: 12px;
	flex-grow: 1;
	border-radius: 5px;
	font-weight: 500;
	font-size: 12.5px;
	display: flex;
	align-items: center;
	column-gap: 5px;
	cursor: pointer;
	transition: all 0.3s;
}
.selectChain:hover {
	border: 1px solid var(--main);
}
