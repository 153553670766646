.popup {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    background: rgba(113, 113, 113, 0.5);
}

.centeredElement {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--bg-dark);
    border-radius: 10px;
    width: calc(100% - 20px);
    max-width: 450px;
    max-height: calc(100vh - 100px);
    overflow: auto;
}
