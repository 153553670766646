.selectFilter {
  border: 1px solid #f0f0f0;
  margin-right: 10px;
  padding: 7px 0;
  padding-left: 15px;
  padding-right: 30px;
}

.info {
    color: var(--main);
}
.info:hover {
    color: var(--main-light);
    cursor: pointer;
}
