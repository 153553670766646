.label {
    color: var(--text-subtitle-bright);
    font-size: 17px;
    margin-bottom: 10px;
    margin-top: 30px;
    font-weight: 700;
}
.wallet {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    margin-bottom: 20px;
}
.card {
    padding: 24px 16px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: var(--border-radius-standard);
    font-weight: 500;
    font-size: 14px;
    color: var(--text-subtitle-bright);
}
.stat {
    margin-block: 18px;
    color: var(--text-subtitle);
    font-weight: 600;
    font-size: 20px;
}
.onUser {
    color: var(--text-subtitle);
}

.name {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.name span {
    cursor: pointer;
}

@media screen and (max-width: 1199px) {
    .wallet {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 20px;
    }
}
@media screen and (max-width: 768px) {
    .wallet {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 20px;
    }
}
