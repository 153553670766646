.sidebar {
    height: 100%;
    float: left;
    width: var(--width-sidebar);
    background-color: var(--light);
    transition: all var(--transition-theme);
    border-right: 1px solid rgba(0, 0, 0, 0.08);
}
.sidebarCollapse {
    float: left;
    height: 100%;
    width: var(--width-sidebar-collapsed);
    background-color: var(--light);
    transition: all var(--transition-theme);
    border-right: 1px solid rgba(0, 0, 0, 0.08);
}
.boxLogo {
    height: var(--height-navbar);
    display: flex;
    align-items: center;
    padding: 0 24px;
    overflow: hidden;
}
.boxLogo img {
    max-height: 25px;
    transition: all 0.5s;
    margin-left: 5px;
}
.sidebarCollapse .boxLogo img {
    max-height: 25px;
    transition: all 0.5s;
    margin-left: 5px;
}

.boxNav {
    display: flex;
    flex-direction: column;
    height: calc(100vh - var(--height-navbar));
}

.boxNav .navigator {
    flex-grow: 2;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 100px;
    padding-bottom: 100px;
}
.boxNav .navigator nav {
    padding-block: 24px;
}
.navigator ul li a {
    display: flex;
    align-items: center;
    width: 100%;
}
.navigator ul .element {
    cursor: pointer;
}

.navigator ul .element div.mainCategory {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 12px 14px;
    margin-bottom: 12px;
    transition: all 0.5s;
    border-right: 3px solid var(--light);
}
.navigator ul .element div.mainCategoryOpenCollapsed {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 12px 14px;
    margin-bottom: 12px;
    border-right: 3px solid var(--light);
}
.mainCategory .innerCategory,
.mainCategoryOpenCollapsed .innerCategory {
    display: flex;
    align-items: center;
    margin-left: 24px;
}

.navigator ul li svg.icon {
    height: 21px;
    width: 20px;
    color: var(--text-subtitle);
}
.navigator ul li svg.arrow {
    color: var(--text-subtitle);
    font-size: 24px;
    cursor: pointer;
    transition: all var(--transition-theme);
}

.sidebarCollapse .navigator ul li svg.arrow {
    display: none;
}
.navigator ul li svg.arrowActive {
    color: var(--text-subtitle);
    font-size: 24px;
    cursor: pointer;
    transform: rotate(180deg);
    transition: all var(--transition-theme);
}
.navigator ul li svg path {
    transition: all 0.5s;
}

.navigator ul li.elementActive {
    position: sticky;
    top: 0;
    z-index: 5;
    cursor: pointer;
    background-color: var(--light);
}
.navigator ul li.elementSticky {
    position: sticky;
    top: 0;
    z-index: 5;
    cursor: pointer;
    background: var(--light);
}

.navigator ul .elementActive div.mainCategory {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px 14px;
    margin-bottom: 12px;
    border-right: 3px solid var(--main);
    transition: all var(--transition-theme);
    color: var(--dark);
}
.navigator ul .elementSticky div.mainCategory {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px 14px;
    margin-bottom: 12px;
    background: var(--light);
    color: var(--light);
    transition: all var(--transition-theme);
}

.navigator ul .elementActive div.mainCategoryOpenCollapsed {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px 14px;
    margin-bottom: 12px;
    background: transparent;
    color: var(--light);
    box-shadow: inset 1px 2px 4px transparent;
    border-right: 3px solid var(--light);
    transition: all var(--transition-theme);
}

.navigator ul .elementActive .categoryText,
.navigator ul .elementActive .arrow {
    transition: all 0.5s;
    color: var(--main);
}
.navigator ul .element .mainCategory:hover .categoryText,
.navigator ul .element .mainCategory:hover .arrow {
    transition: all 0.5s;
    color: var(--main);
}
.navigator ul .elementActive svg path {
    color: var(--main);
}
.navigator ul .element .mainCategory:hover svg:not(.arrow) {
    fill: var(--main);
}
.navigator ul .mainCategoryOpenCollapsed .categoryText,
.navigator ul .mainCategoryOpenCollapsed .arrow {
    transition: all 0.5s;
    color: var(--text-subtitle);
}
.navigator ul .elementActive .mainCategoryOpenCollapsed svg path {
    transition: all 0.5s;
    color: var(--text-subtitle);
}

.element .mainCategoryOpenCollapsed:hover span,
.element .mainCategoryOpenCollapsed:hover svg,
.elementActive .mainCategoryOpenCollapsed:hover span,
.elementActive .mainCategoryOpenCollapsed:hover svg {
    transition: all 0.5s;
    color: var(--main);
}
.navigator ul .element .mainCategoryOpenCollapsed:hover svg path,
.navigator ul .elementActive .mainCategoryOpenCollapsed:hover svg path {
    color: var(--main);
}
.navigator ul li .categoryText {
    font-weight: 500;
    font-size: 15px;
    line-height: 150%;
    letter-spacing: 0.2px;
    transition: all 0.8s;
    color: var(--text-subtitle);
    display: -webkit-box;
    white-space: nowrap;
    overflow: hidden;
}
.sidebarCollapse .navigator ul li .categoryText {
    font-weight: 500;
    font-size: 15px;
    line-height: 150%;
    letter-spacing: 0.2px;
    color: var(--text-subtitle);
    transition: all 0.3s;
    opacity: 0;
    white-space: nowrap;
    overflow: hidden;
}

.navigator div.subCategory {
    margin-left: 46px;
    position: relative;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.2px;
    color: var(--text-subtitle);
}
.navigator div.subCategory::before {
    content: "";
    border-bottom: 3px solid var(--gray-background);
    border-left: 3px solid var(--gray-background);
    border-bottom-left-radius: 10px;
    height: 11px;
    width: 11px;
    display: inline-block;
    position: absolute;
    top: 11px;
    left: 0;
}
.navigator ul li:last-child div.subCategory::before {
    content: "";
    border-bottom: 3px solid var(--gray-background);
    border-left: 3px solid var(--gray-background);
    border-bottom-left-radius: 10px;
    height: 11px;
    width: 11px;
    display: inline-block;
    position: absolute;
    top: 11px;
    left: 0px;
}

.navigator ul li div.subCategory::after {
    content: "";
    height: 100%;
    width: 3px;
    background-color: var(--gray-background);
    position: absolute;
    top: 0;
    left: 0;
}
.navigator ul li:last-child div.subCategory::after {
    content: "";
    height: 12px;
    width: 3px;
    background-color: var(--gray-background);
    position: absolute;
    top: 0;
    left: 0;
}
.navigator div.subCategory .innerSub {
    margin-left: 13px;
}
.navigator div.subCategory .innerSub div.innerElement {
    padding: 12px 12px 16px;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.2px;
    color: var(--text-subtitle);
    transition: var(--transition-theme);
    border-right: 3px solid var(--light);
}
.navigator div.subCategory:hover div.innerElement {
    color: var(--dark);
}
.navigator div.subCategory .innerSub div.innerElementActive {
    width: 100%;
    padding: 12px 12px 16px;
    color: var(--main);
    border-right: 3px solid var(--main);
    transition: all var(--transition-theme);
}

ul.closeDropdown {
    opacity: 0;
    transition: all var(--transition-theme);
    overflow: hidden;
}
.closeDropdown.active {
    opacity: 1;
    transition: all var(--transition-theme);
    overflow: hidden;
    background-color: var(--light);
}

.boxIcon {
    width: 38px;
    transition: all 0.8s;
    display: flex;
}
/* Query */
@media screen and (max-width: 991px) {
    .sidebar {
        width: 0;
    }
    .sidebarCollapse {
        width: 0;
    }
    .sidebarMobile {
        width: var(--width-sidebar);
        position: fixed;
        top: 0;
        z-index: 99;
        left: 0;
        transition: all 0.5s;
        background-color: var(--light);
        border-right: 1px solid rgba(0, 0, 0, 0.08);
    }
    .sidebarCollapseMobile {
        width: var(--width-sidebar);
        position: fixed;
        top: 0;
        left: -350px;
        z-index: 99;
        transition: all 0.5s;
        background-color: var(--light);
    }
}
