/*+ Input */
.inputFieldStyle,
.inputFieldErrorStyle,
.inputFieldSuccessStyle {
    border: none;
    background-color: var(--bg-input);
    border-radius: var(--border-radius-standard);
    border: 1px solid rgba(0, 0, 0, 0.08);
}
.inputFieldStyle input,
.inputFieldSuccessStyle input,
.inputFieldErrorStyle input {
    padding: 15px;
    padding-right: 60px;
    caret-color: var(--dark);
    color: var(--dark);
    border-radius: var(--border-radius-standard);
    transition: all 0.5s;
}
.inputFieldLabelStyle {
    color: var(--text-subtitle);
    font-weight: 600;
    font-size: 16px;
}

/* + Buttons */
.buttonClass,
.buttonDisabledClass {
    background-color: transparent;
    color: var(--main);
    border: 1px solid var(--main);
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border-radius: 10px;
}
.buttonClass:hover {
    background-color: var(--main);
    color: var(--light);
}
.buttonDisabledClass:hover {
    background-color: black;
    filter: grayscale(0.5);
}
.buttonDisabledClass {
    opacity: 0.5;
}

/* +Loader */
.buttonLoader {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px solid rgb(0, 0, 0);
    clip-path: polygon(0% 0%, 100% 100%, 100% 0%, 0% 100%);
    animation: rotate 1.2s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
