.header {
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
}
.image img {
	width: 300px;
}

.button {
	background-color: var(--bg-content-dark);
	border: none;
	color: var(--dark);
	font-weight: 600;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 8px;
	padding: 13px 16px;
	border-radius: 20px;
}

.export {
	background: rgba(135, 39, 108, 0.03);
	border-radius: 31px;
	display: inline-flex;
	align-items: center;
	column-gap: 10px;
	color: var(--main);
	font-weight: 600;
	font-size: 14px;
	padding: 11px;
	cursor: pointer;
	transition: all 0.3s;
	margin-top: 30px;
	margin-left: 20px;
	min-width: 130px;
	min-height: 50px;
}

.exportMessage {
	color: var(--main);
	font-weight: 600;
	font-size: 14px;
	margin-top: 15px;
	margin-left: 20px;
}

.export svg path {
	stroke: var(--main);
	transition: all 0.3s;
}

.export:hover {
	background: var(--main);
	color: white;
}
.export:hover svg path {
	stroke: white;
}
.select {
	display: flex;
	justify-content: flex-end;
	gap: 8px;
	margin-bottom: -24px;
}

.chooseFilter {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 16px;
	margin-top: 30px;
}
.chooseFilter span {
	padding: 5px 10px;
	border-radius: 5px;
	min-width: 87px;
	text-align: center;
	cursor: pointer;
	background-color: rgba(157, 49, 235, 0.16);
	transition: all 0.3s;
}
.chooseFilter span.active {
	background-color: var(--main-light);
	color: var(--light);
	cursor: initial;
}

.chooseFilter span:hover {
	background-color: var(--main-light);
	color: var(--light);
}
