.label {
    color: var(--text-subtitle-bright);
    font-size: 17px;
    margin-bottom: 10px;
    margin-top: 30px;
    font-weight: 700;
}
.deposited {
    display: flex;
    width: 100%;
    column-gap: 10px;
    margin-bottom: 20px;
}

.total {
    padding: 24px 16px;
    flex-basis: 40%;
    border-radius: var(--border-radius-standard);
    font-weight: 500;
    font-size: 14px;
    border: 1px solid rgba(0, 0, 0, 0.08);
}

.graph {
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    column-gap: 10px;
    flex-basis: 100%;
    font-weight: 500;
    font-size: 14px;
    border-radius: var(--border-radius-standard);
    border: 1px solid rgba(0, 0, 0, 0.08);
}

/* Total */
.head {
    color: var(--text-subtitle-bright);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.filter {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    row-gap: 5px;
    text-align: center;
}
.selectedFilter {
    background-color: var(--main);
    color: var(--light);
    border-radius: var(--border-radius-min);
    padding: 2px 5px;
}
.notSelected {
    padding: 2px 5px;
    cursor: pointer;
}

.singleCurrency {
    display: flex;
    column-gap: 20px;
    align-items: center;
    margin-bottom: 12px;
    padding-bottom: 22px;
    border-width: 1px;
    border-style: solid;
    border-top: none;
    border-left: none;
    border-right: none;
    border-image: linear-gradient(
            to left,
            rgb(255, 255, 255),
            rgb(85, 85, 85, 0.2),
            rgb(255, 255, 255)
        )
        1 10%;
}
.singleCurrency .icon {
    width: 30px;
    display: flex;
    align-items: center;
    position: relative;
}
.icon img {
    width: 100%;
}
.icon .chain {
    position: absolute;
    bottom: -3px;
    right: -3px;
    width: 15px;
    border: 1px solid #f0f0f0;
    border-radius: 50%;
}
.stat {
    font-weight: 600;
    font-size: 22px;
}
.suspect {
    font-weight: 400;
    font-size: 17px;
}
.singleCurrency:nth-child(2) .stat {
    color: #87276c;
}
.singleCurrency:nth-child(7) {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
}

@media screen and (max-width: 1199px) {
    .deposited {
        flex-direction: column;
        row-gap: 20px;
    }
    .total,
    .graph {
        flex-basis: 100%;
    }
    .graph > h5 {
        margin-bottom: 20px;
    }
}
