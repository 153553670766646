.header {
    color: var(--text-subtitle);
}

.title {
    margin-bottom: 6px;
    font-weight: 800;
}
.subtitle {
    font-weight: 500;
}
