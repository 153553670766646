.tableContainer {
    overflow-y: hidden;
    overflow-x: hidden;
    position: relative;
}

.innerTable {
    overflow-x: scroll;
    width: 100%;
}

.tableContainer h5 {
    color: var(--dark);
    font-weight: 600;
    margin-bottom: 30px;
}

.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.pagination .entries {
    color: var(--dark);
}

.pagination .paginationButtons {
    display: flex;
}

.paginationItem {
    padding: 5px 10px;
    border: none;
    border-top: 1px solid var(--main);
    border-bottom: 1px solid var(--main);
    background-color: var(--main);
    color: white;
    font-weight: 300;
    font-size: 14px;
    min-width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.tableLoader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 2px solid black;
    clip-path: polygon(0% 0%, 100% 100%, 100% 0%, 0% 100%);
    animation: rotate 1.2s linear infinite;
}

.clampedText {
    white-space: nowrap;
    overflow: hidden;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
}

.buttonRight {
    border: none;
    color: var(--main);
    font-size: 25px;
    transition: 0.4s;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
}

.buttonLeft {
    border: none;
    color: var(--main);
    font-size: 25px;
    transition: 0.4s;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
}

.buttonRight:hover,
.buttonLeft:hover {
    color: var(--main-light);
}

.buttonDisabled {
    cursor: not-allowed;
    opacity: 0.5;
    color: var(--dark);
}

.buttonDisabled:hover {
    cursor: not-allowed;
    opacity: 0.5;
    color: var(--dark);
}

.searchBarContainer {
    width: 100%;
    margin-bottom: 10px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 50px;
}

@media (max-width: 768px) {
    .searchBarContainer {
        flex-wrap: wrap;
    }
}

.inputSearchContainer {
    width: 100%;
    margin-bottom: 10px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 15px;
}

.inputSearch {
    max-width: 250px;
    border: none;
    border-bottom: 1px solid #5d6e82;
    font-size: 15px;
    font-weight: 400;
    padding: 7px 0;
    padding-right: 30px;
    color: black;
    background: transparent
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath fill='gray' d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
        no-repeat;
    background-position: 97% 6px;
    background-size: 17px;
}

.inputSearch::placeholder {
    color: var(--placeholderColor);
    font-weight: 400;
}

.inputSearch:focus,
.inputSearch:active {
    outline: none;
}

.searchButton {
    height: 40px;
}

.noResults {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
}

.selectFilter {
    border: 1px solid #f0f0f0;
    margin-right: 10px;
    padding: 7px 0;
    padding-left: 15px;
    padding-right: 30px;
}
.customDate {
    width: 25px;
}
.datePicker {
    color: #5d6e82;
    font-size: 25px;
    cursor: pointer;
}
.datePicker:hover {
    color: var(--main);
}
.clearDate {
    font-size: 20px;
    color: #5d6e82;
}
.clearDate:hover {
    color: var(--main);
    cursor: pointer;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.paginationInputs {
    width: 100%;
    display: flex;
    margin-top: 25px;
    justify-content: center;
    align-items: center;
}
.paginationInput {
    height: 40px;
    width: 100px;
    border: 1px solid #c5c5c5;
    padding: 0 10px;
    margin-right: 10px;
}
.paginationInputButton {
    height: 40px;
    width: 60px;
}
