.filter {
  margin-top: 30px;
  display: flex;
  align-items: center;
  column-gap: 40px;
  color: #5d6e82;
  font-weight: 500;
  font-size: 16px;
}
.filter > div {
  border-bottom: 1px solid transparent;
  cursor: pointer;
}
.filterSelected {
  border-bottom: 1px solid var(--main) !important;
  color: var(--main);
  cursor: pointer;
  white-space: nowrap;
}

.username {
    margin-top: 20px;
}