.blockUser,
.unblockUser {
	color: var(--error);
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 8px;
	font-weight: 500;
	font-size: 16px;
	transition: all 0.4s;
}
.blockUser:hover,
.unblockUser:hover {
	color: var(--main);
}

.unblockUser {
	color: var(--success-secondary);
}

.info {
	color: var(--main);
}
.info:hover {
	color: var(--main-light);
	cursor: pointer;
}

.export {
	background: rgba(135, 39, 108, 0.03);
	border-radius: 31px;
	display: inline-flex;
	align-items: center;
	column-gap: 10px;
	color: var(--main);
	font-weight: 600;
	font-size: 14px;
	padding: 11px;
	cursor: pointer;
	transition: all 0.3s;
	margin-top: 20px;
	margin-left: 20px;
	min-width: 130px;
	min-height: 44px;
}

.exportMessage {
	color: var(--main);
	font-weight: 600;
	font-size: 14px;
	margin-top: 15px;
	margin-left: 20px;
}

.export svg path {
	stroke: var(--main);
	transition: all 0.3s;
}

.export:hover {
	background: var(--main);
	color: white;
}
.export:hover svg path {
	stroke: white;
}
