.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}
.image img {
    width: 300px;
}

.button {
    background-color: var(--bg-content-dark);
    border: none;
    color: var(--dark);
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    padding: 13px 16px;
    border-radius: 20px;
}
