.adminPanel {
  background-color: #f0f0f0;
  margin-top: 30px;
  border-radius: var(--border-radius-min);
  padding: 30px 20px 20px;
}
.line {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
  text-align: right;
}

.boxFiled {
  flex-basis: calc(100% - 80px);
}
.inputUsername input {
  padding: 15px;
}
.inputPassword input {
  padding: 15px 38px 15px 15px;
}
.label {
  font-size: 14px;
  font-weight: 600;
  flex-basis: 80px;
  margin-bottom: 20px;
}

.box {
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-weight: 600;
  color: var(--input-placeholder);
  padding: 10px;
}

.error {
  color: red;
}

.popup {
  padding: 20px;
  text-align: center;
}
.popupText {
  margin-block: 25px;
}
