.layouting {
	height: 100vh;
	overflow: hidden;
	content: '';
	clear: both;
}
.mainContainer {
	height: 100%;
	width: calc(100% - var(--width-sidebar));
	float: right;
	transition: all var(--transition-theme);
}
.mainContainerCollapse {
	height: 100%;
	width: calc(100% - var(--width-sidebar-collapsed));
	float: right;
	transition: all var(--transition-theme);
}
.contentContainer {
	max-width: 100%;
	background-color: var(--bg-content);
	height: calc(100% - var(--height-navbar));
	overflow-y: auto;
	overflow-x: hidden;
	transition: all 0.7s;
	padding: 30px 28px;
	overflow-y: overlay;
}

@media screen and (max-width: 991px) {
	.mainContainer {
		width: 100%;
	}
	.mainContainerCollapse {
		width: 100%;
	}
}

@media screen and (max-width: 576px) {
	.contentContainer {
		padding: 30px 14px 150px;
	}
}
/*! da fare la query per il content padding  */
