.mainContainer {
    width: 100%;
    height: var(--height-navbar);
    background-color: var(--light);
    transition: all var(--transition-theme);
    box-shadow: 0px 4px 40px 1px rgba(0, 0, 0, 0.03);
    position: relative;
    z-index: 10;
}
.mainContainer .content {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}
.mainContainer .content .contentLeft {
    display: flex;
    align-items: center;
    gap: 15px;
}

.content .menuButton {
    display: flex;
}
.content .menuButton svg {
    font-size: 40px;
    cursor: pointer;
    transform: scaleY(-1);
    color: var(--dark);
    transition: all var(--transition-theme);
}

.actions {
    font-size: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
}
.actions:hover {
    color: var(--main);
}
.actions span {
    font-size: 15px;
    font-weight: 500;
}

@media screen and (max-width: 576px) {
    .actions span {
        display: none;
    }
    .welcome {
        font-size: 14px;
    }
}
