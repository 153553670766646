.login {
    border-radius: 10px;
    padding: 0 5px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 90;
}
.login img {
    width: 100%;
    max-width: 300px;
    margin-bottom: 30px;
}
.inner {
    background: rgba(217, 217, 217, 0.12);
    padding: 30px;
    width: 460px;
}
.content {
    width: 100%;
}
.header {
    text-align: center;
}
.header > div {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.2em;
}
.header > h1 {
    font-weight: 500;
    font-size: 20px;
    text-transform: capitalize;
    margin-block: 11px 50px;
    color: var(--text-subtitle);
}

.inputUsername,
.inputPassword {
    margin-inline: auto;
    margin-block: 10px 20px;
}
.inputUsername {
    margin-bottom: 30px;
}

.buttonLogin {
    margin-top: 50px;
}
.error {
    text-align: center;
    margin-top: 10px;
    color: var(--error);
    font-size: 14px;
}
